import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

Modal.setAppElement('#root');

const ProductForm = () => {
  const navigate = useNavigate();
  const [skuCode, setSKUCode] = useState('');
  const [skuError, setSkuError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [skuDetails, setSkuDetails] = useState(null);

  const skuCodeRef = useRef(null);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (skuCodeRef.current) {
      skuCodeRef.current.focus();
    }
  }, []);

  const openModal = (message) => {
    setModalMessage(message);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSKUCode('');
    setSkuDetails(null);
    skuCodeRef.current.focus();
  };

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter' && skuCode.trim() !== '') {
      event.preventDefault(); // Prevent form submission
      fetchSKUData();
    }
  };

  const fetchSKUData = async () => {
    setSkuError('');  // Clear any previous errors
    setSkuDetails(null);  // Clear previous product details
  
    try {
      const response = await axios.get(`${API_BASE_URL}/shelf/shelf-data/${skuCode}`);
  
      if (response.status === 200) {
        toast.success('SKU code verified successfully');
        setSkuDetails(response.data);
        setSKUCode('');  // Clear the input field after fetching the data
        skuCodeRef.current.focus();  // Focus back on the SKU code input field
      }
    } catch (error) {
      setSkuError('SKU code does not exist');
      openModal('SKU code does not exist');
    }
  };
  

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  return (
    <div className="rounded">
      <div className="row">
        <div className="col-12 p-4 bg-white">
          <form autoComplete="off" className='w-50'>
            <label className="pb-2">Scan Product (SKU Code)</label>
            <div className="position-relative">
              <input
                type="text"
                className="form-control mb-3"
                name="skuCode"
                value={skuCode}
                onChange={(e) => setSKUCode(e.target.value)}
                onKeyDown={handleKeyPress} // API only triggers on Enter
                ref={skuCodeRef}
              />
              {skuError && <p style={{ color: 'red' }}>{skuError}</p>}
            </div>
          </form>
        </div>

        <div className="col-12 text-center">
          {skuDetails ? (
            <div className="bg-light rounded py-4 px-3">
              <h4>Product Details</h4>
              <table className="table table-bordered mt-3">
                <tbody>
                  <tr>
                    <th>SKU Code</th>
                    <td>{skuDetails.sku_code}</td>
                  </tr>
                  <tr>
                    <th  style={{fontSize: '30px', verticalAlign: 'middle'}}>Lane No</th>
                    <td style={{fontSize: '80px', fontWeight: 'bold',  verticalAlign: 'middle'}}>{skuDetails.lane_no}</td>
                  </tr>
                  <tr>
                    <th>Shelf No</th>
                    <td style={{fontSize: '30px', fontWeight: 'bold'}}>{skuDetails.shelf_no}</td>
                  </tr>
                  <tr>
                    <th>EAN</th>
                    <td style={{fontSize: '30px', fontWeight: 'bold'}}>{skuDetails.ean}</td>
                  </tr>
                  <tr>
                    <th>Category</th>
                    <td>{skuDetails.category}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <p>No data available</p>
          )}
        </div>
      </div>

      <ToastContainer />

      <Modal isOpen={isModalOpen} onRequestClose={closeModal} className="modal-ne" overlayClassName="modalne-overlay">
        <div className="modalne-body">
          <p>{modalMessage}</p>
          {modalMessage === 'User not authenticated' ? (
            <button className="btn btn-primary w-100" onClick={handleLogout}>
              Logout
            </button>
          ) : (
            <button className="btn btn-primary w-100" onClick={closeModal}>
              Close
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ProductForm;
