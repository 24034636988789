import React, { useState } from 'react';
import DataDisplayShelf from './components/DataDisplayShelf';
import { Offcanvas, OffcanvasBody, OffcanvasHeader, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Sidebar from './components/sidebar';
import FileUpload from './components/FileUpload';
import GetShelves from './components/GetShelves'
import { FaFileExport, FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Products = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const apiEndpoint = `${process.env.REACT_APP_API_BASE_URL}/shelf/shelf-data`;

    const [isCanvasOpen, setIsCanvasOpen] = useState(false);
    const [isShelfOpen, setIsShelfOpen] = useState(false);
    
    const toggleCanvas = () => setIsCanvasOpen(!isCanvasOpen);
    const toggleShelf = () => setIsShelfOpen(!isShelfOpen);
    
    const toggleModal = () => setModal(!modal);

    const handleSearchChange = (event) => setSearchQuery(event.target.value);

    const convertToCSV = (data) => {
        if (data.length === 0) return '';
        const header = Object.keys(data[0]).join(',');
        const rows = data.map(item => Object.values(item).join(',')).join('\n');
        return [header, rows].join('\n');
    };

    const downloadCSV = (csv, filename) => {
        const csvFile = new Blob([csv], { type: 'text/csv' });
        const downloadLink = document.createElement('a');
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const handleExportSelected = () => {
        if (selectedItems.length === 0) {
            alert('No items selected for export.');
            return;
        }
        const selectedData = data.filter(item => selectedItems.includes(item.sku_code));
        const csv = convertToCSV(selectedData);
        downloadCSV(csv, 'skretail_products_export.csv');
    };

    const handleDeleteSelected = () => {
        toggleModal();
    };


    const confirmDelete = async () => {
        try {
            console.log('Sending request to:', `${process.env.REACT_APP_API_BASE_URL}/shelf/bulk-delete-shelves`);
            console.log('Request body:', { sku_codes: selectedItems });
    
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/shelf/bulk-delete-shelves`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ sku_codes: selectedItems }),
            });
    
            console.log('Fetch request sent', selectedItems); // Added log to confirm request is sent
    
            // Get the response text
            const resultText = await response.text();
            console.log('Response status:', response.status);
            console.log('Response body:', resultText);
    
            if (!response.ok) {
                throw new Error(`Failed to delete items: ${resultText}`);
            }
    
            // If the response contains JSON, you can parse it; otherwise, use the text directly
            let result;
            try {
                result = JSON.parse(resultText);
            } catch (error) {
                // If parsing fails, treat the response as plain text
                result = { message: resultText };
            }
    
            // Close the modal
            toggleModal();
    
            // Show toast message after closing the modal
            setTimeout(() => {
                toast.success(result.message || 'Product Shelves deleted successfully');
            }, 100); // Small delay to ensure modal has closed
    
            // Clear selected items
            setSelectedItems([]);
    
            // Wait for 3 seconds before refreshing the page
            setTimeout(() => {
                window.location.reload();
            }, 3000);
    
        } catch (error) {
            console.error('Error deleting items:', error.message);
            toast.error(`Error deleting items: ${error.message}`);
            // Ensure modal is closed even if there's an error
            toggleModal();
            setSelectedItems([]);
        }
    };
        
    
    return (
        <div className='d-flex w-100 overflow-hidden'>
            <div className='asidebar'>
                <Sidebar />
            </div>
            <div className='main-content'>
                <div className='d-flex align-items-center justify-content-between mb-4'>
                    <h2>Product EAN</h2>
                    <div className='d-flex align-items-center justify-content-end'>
                        <div className="me-3">
                            <input
                                type="text"
                                className="form-control w-300px rounded-50 ps-3"
                                style={{ height: '50px' }}
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <Button color="secondary" className='px-3 me-3 mw-60px' onClick={handleExportSelected}>
                            <FaFileExport />
                        </Button>
                        <Button
                            color="danger"
                            className='px-3 rounded-50 py-2 me-3 mw-60px'
                            onClick={handleDeleteSelected}
                            disabled={selectedItems.length === 0}
                        >
                            <FaTrashAlt />
                        </Button>
                        <Button color="secondary" className='px-5 me-2' onClick={toggleShelf}>
                            Get EAN
                        </Button>
                        <Button color="primary" className='px-5 me-2' onClick={toggleCanvas}>
                            Add EAN
                        </Button>
                    </div>
                </div>
                
                {/* Selected items count */}
                {selectedItems.length > 0 && (
                    <div className='mb-4'>
                        <p>Selected Items: <b>{selectedItems.length}</b> </p>
                    </div>
                )}

                <DataDisplayShelf
                    searchQuery={searchQuery}
                    onExportSelected={handleExportSelected}
                    setData={setData}
                    selectedItems={selectedItems}
                    onSelectionChange={setSelectedItems}
                />
                
                <Offcanvas isOpen={isCanvasOpen} toggle={toggleCanvas} className="custom-canvas-width" direction='end'>
                    <OffcanvasHeader className='mb-4 mx-4 mt-2 border-bottom' toggle={toggleCanvas}>
                        <h3 className='font-weight-bold'> Upload Shelf Info</h3>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        <FileUpload apiEndpoint={apiEndpoint} />
                    </OffcanvasBody>
                </Offcanvas>

                <Offcanvas isOpen={isShelfOpen} toggle={toggleShelf} className="custom-canvas-width-1000px" direction='end'>
                    <OffcanvasHeader className='mb-4 mx-4 mt-2 border-bottom' toggle={toggleShelf}>
                        <h3 className='font-weight-bold'> Upload Shelf Info</h3>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        <GetShelves />
                    </OffcanvasBody>
                </Offcanvas>

                <Modal isOpen={modal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Confirm Delete</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete the selected items?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={confirmDelete}>Delete</Button>{' '}
                        <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Products;
