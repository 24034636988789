import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Tick from './../assets/images/tick.png';
import Cross from './../assets/images/cross.png';
import errorSound from './../assets/audio/windows-error.mp3';
import axios from 'axios';
import ScanImage from './../assets/images/scna1.png'
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root');

const formatData = (data) => {
    if (!data || !Array.isArray(data) || data.length === 0) {
        return [];
    }
    return data.map(item => ({
        sku_code: item.sku_code,
        item_name: item.item_name,
        barcode: item.barcode,
        created_at: new Date(item.created_at).toLocaleDateString(),
        updated_at: new Date(item.updated_at).toLocaleDateString(),
        ean: item.ean,
        image_links: item.image_links
    }));
};

const PrintSku = ({ data }) => {
    const navigate = useNavigate();
    const [skuCode, setSkuCode] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [fetchedData, setFetchedData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [errorField, setErrorField] = useState('');
    const [isFormValid, setIsFormValid] = useState(true);

    const skuCodeRef = useRef(null);
    const skuLabelContainerRef = useRef(null);
    const cartonContainerRef = useRef(null);

    useEffect(() => {
        if (skuCodeRef.current) {
            skuCodeRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (successMessage) {
            toast.success(successMessage);
        }
    }, [successMessage]);

    const playErrorSound = () => {
        const audio = new Audio(errorSound);
        audio.play();
    };

    const openModal = (message, field) => {
        playErrorSound();
        setModalMessage(message);
        setErrorField(field);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        if (errorField === 'sku_code') {
            setSkuCode('');
            skuCodeRef.current.focus();
        }
    };

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const handleSkuCodeChange = async (event) => {
        const sku_code = event.target.value.trim();
        setSkuCode(sku_code);
        setError('');
        setSuccessMessage('');
    
        if (!sku_code) {
            return;
        }
    
        try {
            const response = await fetch(`${API_BASE_URL}/sku/get-data-by-id?sku_code=${sku_code}`);
            if (!response.ok) {
                throw new Error('Data not found');
            }
            const responseData = await response.json();
    
            console.log('API Response:', responseData); // Debugging line to see the actual data format
    
            // Check if responseData is an object
            if (responseData && typeof responseData === 'object') {
                setFetchedData([responseData]); // Wrap the object in an array
                setSuccessMessage('Data fetched successfully');
            } else {
                throw new Error('Unexpected data format');
            }
        } catch (error) {
            console.error('Error fetching data:', error); // Debugging line
            setError(error.message);
            setFetchedData([]);
            openModal(error.message, 'sku_code');
        }
    };
    

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSkuCodeChange(event);
        }
    };

    const refreshAccessToken = async () => {
        const refreshToken = localStorage.getItem('refreshToken');
        const accessToken = localStorage.getItem('accessToken');
        if (!refreshToken) {
            throw new Error('No refresh token found');
        }
      
        const response = await fetch(`${API_BASE_URL}/auth/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refreshToken }),
        });
      
        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.error || 'Failed to refresh token');
        }
      
        // Save new tokens to localStorage
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
      
        return data.accessToken;
      };
      
      const handleLogout = () => {
        console.log('Logging out...'); // Debugging log
        localStorage.clear(); // Update the key here
        navigate('/login'); // Redirect to the login page after logout
      };
    

    const handlePrintAndSave = async (buttonId) => {
        let token = localStorage.getItem('accessToken');
  if (!token) {
    console.log('No access token found');
      openModal('User not authenticated');
      return;
  }
    
    
        try {
                 // Decode the token and check for expiration
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
          // Token expired, attempt to refresh
          console.log('Access token is expired, attempting to refresh...');
          try {
              token = await refreshAccessToken();
          } catch (error) {
              openModal('Session expired. Please log in again.');
              return;
          }
      }

            setIsFormValid(false);
    
            const sizeConfig = {
                sku_label_button: {
                    width: 226.8,   // 25mm converted to pixels
                    height: 94.5,   // 60mm converted to pixels
                    orientation: 'landscape',  // Portrait for SKU Label
                    printedLabel: 'Sku Label',
                    containerRef: skuLabelContainerRef
                },
                carton_button: {
                    width: 567,     // 150mm converted to pixels
                    height: 378,    // 100mm converted to pixels
                    orientation: 'landscape',  // Landscape for Carton Label
                    printedLabel: 'Carton Label',
                    containerRef: cartonContainerRef
                }
            };
    
            const { width: canvasWidthPx, height: canvasHeightPx, orientation, printedLabel, containerRef } = sizeConfig[buttonId];
    
            const pxToPt = (px) => px * 72 / 96;
    
            if (containerRef.current) {
                const container = containerRef.current;
    
                const cleanContainerContent = (element) => {
                    const walker = document.createTreeWalker(element, NodeFilter.SHOW_TEXT, null, false);
                    let node = walker.nextNode();
                    while (node) {
                        node.nodeValue = node.nodeValue.replace(/[^\u0000-\u007F]/g, " ");
                        node = walker.nextNode();
                    }
                };
    
                // Create PDF with the correct dimensions
                const pdf = new jsPDF({
                    orientation: orientation,
                    unit: 'px',
                    format: orientation === 'portrait'
                        ? [pxToPt(canvasWidthPx), pxToPt(canvasHeightPx)]
                        : [pxToPt(canvasHeightPx), pxToPt(canvasWidthPx)]  // Swap width and height for landscape
                });
    
                const marginLeftPt = pxToPt(0); // No margins to ensure full content fit
                const marginTopPt = pxToPt(0);  // No margins to ensure full content fit
    
                const recordsToSave = [];
    
                for (const barcodeItem of fetchedData) {
                    if (!barcodeItem) continue;
    
                    cleanContainerContent(container);
    
                    // Generate high-resolution canvas
                    const canvas = await html2canvas(container, {
                        scale: 4,  // Higher scale for better quality
                        useCORS: true,
                        backgroundColor: '#ffffff'
                    });
    
                    const imgData = canvas.toDataURL('image/png');
                    const imgWidthPx = canvas.width;
                    const imgHeightPx = canvas.height;
    
                    // Convert image dimensions from pixels to points
                    const imgWidthPt = pxToPt(imgWidthPx);
                    const imgHeightPt = pxToPt(imgHeightPx);
    
                    // Directly add the image to fit PDF dimensions
                    if (fetchedData.indexOf(barcodeItem) > 0) {
                        pdf.addPage();
                    }
    
                    // Add the image with exact dimensions to fit the PDF
                    pdf.addImage(imgData, 'PNG', marginLeftPt, marginTopPt, pxToPt(canvasWidthPx), pxToPt(canvasHeightPx));
    
                    const corporatecode = barcodeItem.sku_code || 'N/A';
                    const brand = barcodeItem.item_name || 'N/A';
                    const manufacturedate = barcodeItem.createdDate || 'N/A';
    
                    if (!corporatecode || !brand || !manufacturedate) {
                        throw new Error('Missing data fields for saving print details');
                    }
    
                    recordsToSave.push({
                        corporatecode,
                        brand,
                        createdDate: new Date().toISOString(),
                        printed_label: printedLabel,
                    });
                }
    
                const pdfBlob = pdf.output('blob');
                const url = URL.createObjectURL(pdfBlob);
                window.open(url, '_blank');
    
                const response = await fetchWithToken(`${API_BASE_URL}/sku/saveprintdetails`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(recordsToSave),
                });
    
                const responseData = await response.json();
                if (!response.ok) {
                    throw new Error(responseData.error || 'Failed to save print details');
                }
    
                toast.success('Print details saved successfully');
                setTimeout(() => {
                    setSkuCode('');
                    setSuccessMessage('');
                    skuCodeRef.current.focus();
                }, 2000);
            }
        } catch (error) {
            toast.error(error.message || 'Error saving print details');
        } finally {
            setIsFormValid(true);
        }
    };
    
    

    const fetchWithToken = async (url, options = {}) => {
        let token = localStorage.getItem('accessToken');
        options.headers = {
            ...options.headers,
            'Authorization': `Bearer ${token}`,
        };

        let response = await fetch(url, options);

        if (response.status === 403) {
            token = await refreshAccessToken();
            options.headers['Authorization'] = `Bearer ${token}`;
            response = await fetch(url, options);
        }

        return response;
    };

    const formattedData = formatData(fetchedData);
    const currentDateTimeIST = new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' });
console.log(currentDateTimeIST);


    return (
        <div className='rounded'>
        <div className='row'>
            <div className='col-5 p-4 bg-white'>
                <form autoComplete='off'>
                    <label className='pb-2'>Scan SKU</label>
                    <div className='position-relative'>
                        <textarea
                            className='form-control mb-3'
                            name="sku_code"
                            value={skuCode}
                            onChange={(e) => setSkuCode(e.target.value)}
                            onKeyPress={handleKeyPress}
                            ref={skuCodeRef}
                        />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        {error && <img className='placeit-oninput' src={Cross} alt={error} />}
                        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                        {successMessage && <img className='placeit-oninput' src={Tick} alt={successMessage} />}
                    </div>
                </form>
    
                <div className='mt-3 mb-3 p-2 rounded bg-light'>
                    {formattedData.length > 0 ? (
                        <div id='sku_label_container' style={{height: '94.5px', width: '226.8px'}} className='bg-white position-relative' ref={skuLabelContainerRef}>
                            {formattedData.map((item, index) => (
                                <div className='print-label d-flex flex-column position-relative' style={{height: '94.5px', width: '226.8px', padding: '5px'}} key={index}>
                                    <div className='label-itemname' style={{
                                                                            width: '96%',
                                                                            fontWeight: '600',
                                                                            fontSize: item.item_name.length > 90 ? '7px' : '8px',
                                                                            height: '30px',
                                                                            margin: item.item_name.length > 50 ? '-2px 2% 0': "0 2%",
                                                                            textAlign: 'center'
                                                                            }}
                                                                            >
                                                                            {item.item_name}
                                                                            </div>
                                    <div className='label-barcode'
                                    style={{
                                         textAlign: 'center'
                                    }}>
                                        <img
                                            src={`data:image/png;base64,${item.barcode}`}
                                            //src = {ScanImage}
                                            alt={item.barcode}
                                            style={{ 
                                                margin: '0 auto',
                                                maxWidth: '160px',   // Ensure width matches the generated size
                                                height: 'auto',   // Ensure height matches the generated size
                                                display: 'block',
                                                objectFit: 'contain' // Preserve aspect ratio without distortion
                                            }}
                                        />
                                    </div>
                                    <div
                                        className='label-ean'
                                        style={{
                                            position: 'absolute',
                                            right: '0px',
                                            top: '50%',
                                            transform: item.item_name.length > 50 ? 'translate(-50%, 270%) rotate(0deg)' : 'translate(-50%, 235%) rotate(0deg)',
                                            textAlign: 'center',
                                            fontWeight: '600',
                                            fontSize: '8px'
                                        }}
                                    >
                                        {item.ean}
                                    </div>
                                    <div className='label-skucode'
                                    style={{
                                        fontWeight: '600',
                                        textAlign: 'center',
                                        fontSize: '9px'
                                    }}>{item.sku_code}</div>
                                     <div
  style={{
    fontSize: '7px',
    fontWeight: '600',
    textAlign: 'left',
    margin: '5px',
    marginLeft: '0px',
    marginTop: item.item_name.length > 52 ? '-3px' : "0",
    padding: '0px'
  }}
>
  {new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}
</div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p style={{ color: 'red', display: 'none' }}>No data available for this SKU</p>
                    )}
                </div>
    
                <button
                    type="button"
                    className='btn btn-primary mr-2'
                    onClick={() => handlePrintAndSave('sku_label_button')}
                    disabled={!isFormValid}
                >
                    Print SKU Label
                </button>
                <div className='mt-3 mb-3 p-2 rounded bg-light'>
                {formattedData.length > 0 ? (
  <div id='carton_container' ref={cartonContainerRef} className='bg-white position-relative'>
    {formattedData.map((item, index) => (
      <div className='print-label-large' key={index} style={{marginLeft: '5px', padding:'7px 0'}}>
        <div
          style={{
            fontSize: '46px',
            fontWeight: 'bold',
            textAlign: 'center',
            border: '3px solid',
            margin: '5px',
            marginLeft: '0px',
            marginTop: '0px',
            height: '90px',
            lineHeight: '90px'
          }}
        >
          {item.ean}
        </div>
        <div
          style={{
            fontSize: '16px',
            fontWeight: '600',
            textAlign: 'center',
            border: '3px solid',
            margin: '5px',
            marginLeft: '0px',
            marginTop: '0px',
            padding: '10px 5px'
          }}
        >
          {item.item_name}
        </div>
        <div
          style={{
            fontSize: '16px',
            fontWeight: '600',
            textAlign: 'center',
            border: '3px solid',
            margin: '5px',
            marginLeft: '0px',
            marginTop: '0px',
            padding: '10px 5px'
          }}
        >
          {item.sku_code}
        </div>
        <div
  style={{
    fontSize: '16px',
    fontWeight: '600',
    textAlign: 'center',
    border: '3px solid',
    margin: '5px',
    marginLeft: '0px',
    marginTop: '0px',
    padding: '10px 5px'
  }}
>
  {new Date().toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}
</div>

      </div>
    ))}
  </div>
) : (
  <p style={{ color: 'red', display: 'none' }}>No data available for this SKU</p>
)}
</div>
                <button
                    type="button"
                    className='btn btn-primary ml-2'
                    onClick={() => handlePrintAndSave('carton_button')}
                    disabled={!isFormValid}
                >
                    Print Carton Label
                </button>
            </div>
        

                <div className='col-7 p-4 bg-light'>
                    <div>
                        {formattedData.map((item, index) => (
                            <div className='print-label-large' key={index}>
                               
                                <div className='label-image'>
                                    <img src={item.image_links} alt='item image' className='w-100' />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
           

            <Modal
  isOpen={isModalOpen}
  onRequestClose={closeModal}
  className="modal-ne"
  overlayClassName="modalne-overlay"
>
  <div className="modalne-body">
    <p>{modalMessage}</p>
    
    {/* Show the Logout button if the modal is for authentication error */}
    {modalMessage === 'User not authenticated' ? (
      <button className="btn btn-primary w-100" onClick={handleLogout}>Logout</button>
    ) : (
      <button className="btn btn-primary w-100" onClick={closeModal}>Close</button>
    )}
  </div>
</Modal>

            <ToastContainer />
        </div>
    );
};

export default PrintSku;
